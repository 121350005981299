import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { refundRoutes } from "../../routing/routes.config";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const sidebarTitle = "Member reimbursement";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const commonPageProps = usePage();
  const { steps, activeStepIndex, canNavigateToPreviousStep, navigateToPreviousStep } = useRoutes();

  const contentTitle = refundRoutes.find(
    (route) => route.path.toLowerCase() === location.pathname.toLowerCase(),
  )?.heading;

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => navigateToPreviousStep && navigateToPreviousStep(),
  };

  const onStepClick = (_: string, stepIndex: number) => {
    canNavigateToPreviousStep && activeStepIndex !== stepIndex && navigate(steps[stepIndex].path);
  };

  return {
    contentTitle,
    sidebarTitle,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
