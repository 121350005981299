import { useGetPreviousPageSessionState, useSessionStateInitialiser } from "raci-react-library";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteInformation, allRoutes, refundRoutes } from "../routes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesResults {
  isInRefundFlow: boolean;
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  navigateToPreviousStep?: () => void;
  canNavigateToPreviousStep: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialiseSessionStates = useSessionStateInitialiser();

  useEffect(() => {
    initialiseSessionStates(
      allRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);

  const { path: previousPageUrl } = useGetPreviousPageSessionState();
  const isInRefundFlow = refundRoutes.filter((item) => item.path === location.pathname).length > 0;

  const steps: StepInformation[] = isInRefundFlow ? refundRoutes : [];

  const activeStepIndex = isInRefundFlow ? steps.findIndex((item) => item.path === location.pathname) : undefined;
  const totalStepCount = isInRefundFlow ? steps.length : undefined;

  const navigateToPreviousStep = useMemo(() => {
    return isInRefundFlow && previousPageUrl && (activeStepIndex ?? 0) > 0
      ? () => navigate(previousPageUrl, { replace: true })
      : undefined;
  }, [navigate, isInRefundFlow, activeStepIndex, previousPageUrl]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep &&
    steps.indexOf(refundRoutes[refundRoutes.length - 1]) !== activeStepIndex &&
    activeStepIndex !== 0;

  return {
    isInRefundFlow,
    steps,
    formRoutes: refundRoutes,
    activeStepIndex,
    totalStepCount,
    canNavigateToPreviousStep,
    navigateToPreviousStep,
  };
};

export default useRoutes;
