import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFlowState from "../../hooks/useFlowState";
import {
  ADD_ACCOUNT_PAGE_URL,
  CONFIRMATION_PAGE_URL,
  CONFIRMATION_PATH,
  ENTER_DETAILS_PATH,
  SESSION_TIMEOUT_PATH,
  VERIFY_TOKEN_PATH,
} from "../routes.config";

export interface RouteGuardProps {
  disableChecks?: string[];
}

export const RouteGuard = ({ disableChecks = [] }: RouteGuardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [flowState] = useFlowState();
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();

  /**
   * The below will check if the current page can
   * be navigated to by checking if the previous
   * page has been completed.
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;
    if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
      navigate(previousPageUrl, { replace: true });
    }
  }, [navigate, disableChecks, previousPageUrl, isPreviousPageCompleted, location.pathname]);

  /**
   * Forces the user to a particular page, depending on their current flow
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const blockedPathsWhenVerified = [ENTER_DETAILS_PATH, VERIFY_TOKEN_PATH];

    // If the user has completed their submission, and tries to transition to a previous page
    if (flowState.isCompleted && (currentLocation !== CONFIRMATION_PATH && currentLocation !== SESSION_TIMEOUT_PATH)) {
      navigate(CONFIRMATION_PAGE_URL);
    } else if (flowState.isOtpVerified && blockedPathsWhenVerified.includes(currentLocation)) {
      navigate(ADD_ACCOUNT_PAGE_URL);
    }
  }, [navigate, location.pathname, flowState]);

  return <></>;
};

export default RouteGuard;
