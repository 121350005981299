import { PhoneNumber } from "raci-react-library";

export const SESSION_KEY_PREFIX = "RAC_MRO_";
export const NON_BREAKING_SPACE = "\u00a0";

// <------------------>
//  API Client
// <------------------>
export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const LOOKUP_SERVICE_API_BASE_URL = process.env.REACT_APP_LOOKUP_API_BASE_URL ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";

// <------------------>
//  Atoms
// <------------------>
export const ATOM_SESSION_ID = "AtomSessionId";

// <---------------------->
//  Reimbursement Contacts
// <---------------------->
export const REIMBURSEMENT_EMAIL_ADDRESS = "insurancereimbursements@rac.com.au";
export const REIMBURSEMENT_PHONE_NUMBER: PhoneNumber = {
  href: "tel:1300657627",
  display: "1300 657 627",
  displayWithoutSpaces: "1300657627",
};

// <----------------------->
//  One Time Passcode (OTP)
// <----------------------->
export const MAX_OTP_ATTEMPTS = 6;
export const MAX_OTP_CODE_REQUESTS = 6;
export const VERIFICATION_CODE_LENGTH = 6;
export const VERIFICATION_STATUS_APPROVED = "approved";
export const VERIFICATION_STATUS_PENDING = "pending";
export const RESENDING_OTP_BACKDROP_LOADING_MESSAGE = "Sending";
export const OTP_ERROR_NO_MATCH = "Sorry, that code didn't match. Please try again.";
export const OTP_ERROR_EXPIRED = "Sorry, your code has expired. Please request a new code.";
