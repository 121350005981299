import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { MemberRefundApiClient } from "raci-member-refund-clientproxy";
import { useSessionIdStorageKey } from "raci-react-library";
import { NpeEnvironment, useApiClient } from "raci-react-library-npe";
import { BFF_API_BASE_URL, LOOKUP_SERVICE_API_BASE_URL } from "../../shared/constants";

const ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ?? "") as NpeEnvironment;
const NPE_CLIENT_ID = process.env.REACT_APP_NPE_OAUTH_CLIENT_ID ?? "";
const NPE_AUTHORITY = process.env.REACT_APP_NPE_OAUTH_AUTHORITY ?? "";

export const useNpeBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();

  return useApiClient({
    Client: MemberRefundApiClient,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey,
    environment: ENVIRONMENT,
    npeClientId: NPE_CLIENT_ID,
    npeAuthority: NPE_AUTHORITY,
  });
};

export const useNpeLookupServiceApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();

  return useApiClient({
    Client: LookupServiceApiClient,
    apiBaseUrl: LOOKUP_SERVICE_API_BASE_URL,
    sessionIdStorageKey,
    environment: ENVIRONMENT,
    npeClientId: NPE_CLIENT_ID,
    npeAuthority: NPE_AUTHORITY,
  });
};
