import { LinkProps } from "@mui/material";
import { FooterProps, RacwaResponsiveHeaderProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, PhoneButton } from "raci-react-library";
import { REIMBURSEMENT_PHONE_NUMBER } from "../../constants";

type CommonPageProps = Pick<
  RacwaStepperTemplateProps,
  "responsiveHeaderProps" | "headerEndAction" | "notificationBannerContent" | "showNotificationBanner" | "footerProps"
>;

const getLinkProps = (link?: string): Partial<LinkProps> => ({
  href: link ?? EMPTY_URL,
});

export const headerPhoneButton = <PhoneButton id={"header-phone-button"} phone={REIMBURSEMENT_PHONE_NUMBER} />;

export const usePage = (): CommonPageProps => {
  const responsiveHeaderProps: RacwaResponsiveHeaderProps = {
    NavBreadcrumbsProps: {
      homeLink: process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL,
    },
  };

  const footerProps: FooterProps = {
    variant: "sidebar",
    privacyLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE),
    securityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_SECURITY_PAGE),
    disclaimerLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_DISCLAIMER_PAGE),
    accessibilityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_ACCESSIBILITY_PAGE),
  };

  let notificationBannerContent;

  let headerEndAction = headerPhoneButton;

  /*
   ** WARNING:
   ** KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO TO THE BELOW IF STATEMENT
   ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET ENVIRONMENT
   ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE AND SHIP CODE
   ** (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED JAVASCRIPT ARTIFACTS)
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { HeaderButtons, TestBanner } = require("raci-react-library-npe");
    const { EnvironmentHealthStatus } = require("../../../__NPE__/EnvironmentHealthStatus");

    notificationBannerContent = <TestBanner />;

    headerEndAction = (
      <HeaderButtons
        phoneButton={headerPhoneButton}
        npeButtons={[<EnvironmentHealthStatus />]}
        isUat={process.env.REACT_APP_ENVIRONMENT === "UAT"}
      />
    );
  }

  return {
    responsiveHeaderProps,
    headerEndAction,
    notificationBannerContent,
    showNotificationBanner: !!notificationBannerContent,
    footerProps,
  };
};

export default usePage;
