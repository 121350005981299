import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop, SessionStatePrefixProvider, SessionStatusProvider, TrackPageChanges } from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import FlowStateProvider from "./shared/components/FlowStateProvider";
import { SESSION_KEY_PREFIX } from "./shared/constants";
import useCreateSession from "./shared/hooks/useCreateSession";
import { applicationInsightsPlugin } from "./shared/utils/applicationInsights";
import Views from "./views";

export const App = () => (
  <RacwaThemeProvider>
    <RecoilRoot>
      <FlowStateProvider>
        <AppInsightsContext.Provider value={applicationInsightsPlugin}>
          <Backdrop />
          <BrowserRouter>
            <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
              <SessionStatusProvider useCreateSession={useCreateSession}>
                <Views />
              </SessionStatusProvider>
            </SessionStatePrefixProvider>
            <TrackPageChanges />
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </FlowStateProvider>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
