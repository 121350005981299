import { MemberRefundApiClient } from "raci-member-refund-clientproxy";
import { useApiClient as useLibraryApiClient, useSessionIdStorageKey } from "raci-react-library";
import { BFF_API_BASE_URL } from "../../constants";

const useRealBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();

  return useLibraryApiClient({
    Client: MemberRefundApiClient,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey,
  });
};

export let useApiClient = useRealBffApiClient;

// Use the NPE API Clients when internal auth is required
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT"
) {
  const { useNpeBffApiClient } = require("../../../__NPE__/useNpeApiClient");

  useApiClient = useNpeBffApiClient;
}

export default useApiClient;
