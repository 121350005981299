import { useContext } from "react";
import { FlowStateContext } from "../../components/FlowStateProvider";

export const useFlowState = () => {
  const context = useContext(FlowStateContext);

  if (!context) {
    throw new Error("useFlowState must be used within a FlowStateProvider");
  }

  return context;
};

export default useFlowState;
