import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { allRoutes } from "../routes.config";

const defaultTitle = process.env.REACT_APP_SITE_TITLE || "";
const envPrefix = process.env.REACT_APP_ENVIRONMENT !== "PRD" ? `**${process.env.REACT_APP_ENVIRONMENT}** - ` : "";

const createPageTitle = (page: string) => {
  return `${envPrefix}Member Reimbursement - ${page}`;
};

export const TitleGuard = () => {
  const location = useLocation();

  useEffect(() => {
    if (document) {
      const route = allRoutes.find((item) => item.path === location.pathname);
      document.title = route?.name ? createPageTitle(route.name) : defaultTitle;
    }
  }, [location.pathname]);

  return <></>;
};
