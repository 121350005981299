import { Dispatch, PropsWithChildren, SetStateAction, createContext, useEffect, useState } from "react";
import { SESSION_KEY_PREFIX } from "../../constants";

const FLOW_STATE_SESSION_KEY = `${SESSION_KEY_PREFIX}FLOW_STATE`;

export interface FlowState {
  /** Has the member completed an OTP verification */
  isOtpVerified: boolean;
  /** Has the member completed the flow */
  isCompleted: boolean;
}

export const FlowStateContext = createContext<[FlowState, Dispatch<SetStateAction<FlowState>>] | undefined>(undefined);

export const FlowStateProvider = ({ children }: PropsWithChildren) => {
  const [flowState, setFlowState] = useState(() => {
    const storedFlowState = sessionStorage.getItem(FLOW_STATE_SESSION_KEY);
    return storedFlowState ? JSON.parse(storedFlowState) : { isCompleted: false };
  });

  useEffect(() => {
    sessionStorage.setItem(FLOW_STATE_SESSION_KEY, JSON.stringify(flowState));
  }, [flowState]);

  return <FlowStateContext.Provider value={[flowState, setFlowState]}>{children}</FlowStateContext.Provider>;
};

export default FlowStateProvider;
